
.appoint-list-container {
    border: 1px solid #edeeee;
    border-radius: 5px;
    background-color: white;
    flex:1;
  }
  
  .appoint-upcoming-filters {
      padding: 16px 20px;
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #edeeee;
  
      .filter {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4px;
  
          .title {
              margin: 0;
              color: #c4c4c4;
              font-weight: bold;
              font-size: 12px;
          }
  
          .clear {
              margin: 0;
              display: flex;
              align-items: center;
              gap: 4px;
              color: #1c63ec;
              font-size: 13px;
              text-decoration: underline;
              border: 0;
              background-color: transparent;
          }
      }
  }
  
  .appoint-upcoming-date {
      padding: 20px 10px;
      font-weight: bold;
      border-bottom: 1px solid #edeeee;
  }
  
  .appoint-upcoming-list {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #edeeee;
  }
  
  .appoint-upcoming-list .list-item {
      display: flex;
      align-items: center;
      padding: 32px 20px;
      border-bottom: 1px solid #edeeee;

      &:last-of-type {
        border: 0;
      }
  
      .info {
          display: flex;
          align-items: center;
          gap: 40px;
  
          .time-indicator {
              display: flex;
              align-items: center;
              gap: 50px;
  
              .indicator {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;

                  &.past {
                    background-color: gray;
                  }

              }
  
              .time {
                  font-size: 14px;
                  white-space: nowrap;
              }
          }
  
          .event-details {
              display: flex;
              flex-direction: column;
              
              .person-name {
                  font-size: 14px;
  
                  .text {
                      font-weight: bold;
                  }
              }
  
              .event-name {
                  font-size: 14px;
                  font-weight: bold;
              }
          }

          .event-info {
            font-size: 14px;
          }
  
          
          .host-info {
              font-size: 14px;
          }
      }
  
      .details {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: flex-end;
  
          .btn-detail {
              display: flex;
              gap: 8px;
              align-items: center;
              color: #6b7280;
              font-size: 14px;
              border: 0;
              background-color: transparent;
          }
      }
  }
  
  .appoint-upcoming-end-message {
      display: flex;
      justify-content: center;
      padding: 20px;
  
      p {
          font-size: 14px;
          color: #babec6;
          font-weight: bold;
      }
  }

  .circle-option-select {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 9px !important; 
    color: white;
    background-color: #1c63ec;
    border-radius: 50%;
    padding: 3px 7px;
}