@import url(https://sbxcloud.com/www/ibuyflowers/cms-ui/css/cms_common.css);

* {
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
}

.cms_accordeon_container {
  max-width: 800px;
  position: relative;
  margin: 10px 20px;
}

.cms_accordeon_container .cms_accordeon_title {
  position: relative;
  padding: 10px;
  padding-left: 30px;
  cursor: pointer;
}

.cms_accordeon_title::before {
  content: '+';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.cms_accordeon_title.cms_accordeon_active::before {
  content: '-';
}

.cms_accordeon_container .cms_accordeon_content {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  height: 0;
}

.cms_accordeon_container .cms_accordeon_content p {
  padding: 10px;
  padding-left: 30px;
  margin: 0;
}

/*START STYLES TO CMS PRODUCTS LIST*/
.__cms_flower .__cms_flower_card,
div[cms-slider-content="true"] .__cms_flower_card {
  padding: 10px;
  border-radius: 9.24px;
  border: 1px solid #EDEFED;
  background: #FFFFFF;
}

.cms-content-product-row {
  display: grid;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 30px;
}

.cms-product-row-title {
  font-size: 20px;
}

@media (min-width: 310px) {
  .cms-content-product-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 640px) {
  .cms-content-product-row {
    grid-template-columns: repeat(auto-fill, 197.4px);
  }
  .__cms_flower .__cms_flower_card,
  div[cms-slider-content="true"] .__cms_flower_card {
    padding: 18px;
  }
}

@media (min-width: 768px) {
  .cms-content-product-row {
    grid-template-columns: repeat(auto-fill, 304px);
  }
  .cms-product-row-title {
    font-size: 32px;
  }
}

@media (min-width: 1024px) {
  .cms-content-product-row {
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .cms-content-product-row {
    gap: 16px;
  }
}

.__cms_flower .__cms_flower_card .__cms_flower_card_content,
div[cms-slider-content="true"] .__cms_flower_card .__cms_flower_card_content {
  width: 100%;
  height: 269.05px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 9.24px;
  position: relative;
}

@media (max-width: 768px) {
  .__cms_flower .__cms_flower_card .__cms_flower_card_content,
  div[cms-slider-content="true"] .__cms_flower_card .__cms_flower_card_content {
    height: 147px;
  }

  .cms-md-hidden {
    display: none !important;
  }

  .cms-md-visible {
    display: block !important;
  }
}

@media (max-width: 350px) {
  .__cms_flower .__cms_flower_card .__cms_flower_card_content,
  div[cms-slider-content="true"] .__cms_flower_card .__cms_flower_card_content {
    height: 100px;
  }
}


/* @media (max-width: 429px) {
    .__cms_flower .__cms_flower_card .__cms_flower_card_content,
    div[cms-slider-content="true"] .__cms_flower_card .__cms_flower_card_content {
        height: 100px;
    }
} */

.cms-justify-between {
  justify-content: space-between;
}

.cms-flex {
  display: flex;
}

.cms-hidden {
  display: none;
}

.cms-visible {
  display: block;
}

.cms-justify-center {
  justify-content: center;
}

.cms-items-center {
  align-items: center;
}

.cms-bg-white {
  background-color: white;
}

.cms-rounded-full {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}


.cms-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


/*END STYLES TO CMS PRODUCTS LIST*/

.cms-component-1 {
  display: grid;
  gap: 20px;
  max-width: 1100px;
  grid-template-areas: "photo"
                        "divtext";

}

.cms-component-1 {
  grid-template-columns: 100%;
}

@media (min-width: 1100px) {
  .cms-component-1 {
    width: 80%;
    grid-template-columns: 40% 60%;
    grid-template-areas: "divtext photo";
  }
}

.cms-subcomponent-1 {
  grid-area: divtext;
}


.cms-subcomponent-1 h2 {
  font-size: 24px;
  line-height: 28.8px;
  margin: 0;
  margin-bottom: 10px;
}

.cms-subcomponent-1 p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 10px;
}

.cms-subcomponent-1 .cms-div-a {
  margin-top: 10px;
}

.cms-subcomponent-2 {
  grid-area: photo;
}

.cms-subcomponent-2 img {
  width: 100%;
  max-width: 100%;
}


@media (min-width: 1100px) {
  .cms-subcomponent-1 h2 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  .cms-subcomponent-1 p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
  }

  .cms-subcomponent-1 .cms-div-a {
    margin-top: 30px;
  }

  .cms-subcomponent-2 img {
    width: 636px;
    height: 363px;
  }
}

/* NAVBAR */
.cms-nav {
  display: flex;
  padding: 10px;
  width: 100%;
  background-color: rgb(10, 188, 255);
  justify-content: center;
  position: fixed;
  z-index: 50;
}

.cms-nav-div {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  max-width: 1280px;
}

.cms-nav-div-logo {
  padding: 10px;
  display: flex;
  width: 20%;
}

.cms-nav-div-options {
  padding-right: 10px;
  padding-left: 10px;
  width: 80%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.cms-nav-mobile {
  position: absolute;
  transform: translateX(-2500px);
  height: 100vh;
  width: 100vw;
  background-color: rgb(10, 188, 255);
  z-index: 51;
  transition: transform 0.3s ease-out;
}

.cms-nav-mobile a {
  width: fit-content;
}

.cms-menu-toggle {
  display: none;
}

.cms-menu-toggle:checked + .cms-nav-mobile {
  transform: translateX(0);
}

.cms-btn-menu {
  display: none;
}

@media (max-width: 900px) {
  .cms-nav-div {
    justify-content: space-between;
    align-items: center;
  }

  .cms-nav-div-options {
    display: none;
  }
  .cms-btn-menu {
    display: block;
  }
}

@media (max-width: 992px) {
  .cms-nav-div-options {
    width: 100%;
  }
}

/* STYLES FOR PRODUCTS CARD 2*/
.cms-container-1 {
  display: grid;
  gap: 20px;
  /* max-width: 1280px; */
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 1023px) {
  .cms-container-1 {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .cms-product-width {
    width: 86%;
    max-width: 1160px;
    margin: 0 auto;
  }
}

.cms-subcontainer-1 h2 {
  font-size: 20px;
  line-height: 28.8px;
}

.cms-btn-shop {
  display: none;
}

.cms-subcontainer-1 .cms-subtitle {
  font-size: 14px;
}

.cms-subcontainer-1 .cms-description {
  display: none;
  font-size: 16px;
  line-height: 24px;
}

.cms-subcontainer-1 {
  padding-left: 20px;
}

.cms-slider-btn-l,
.cms-slider-btn-r {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.cms-slider-btn-l {
  left: -60px;
}

.cms-slider-btn-r {
  right: -60px;
}

@media (max-width: 1023px) {
  .cms-container-parent {
    background-image: url('') !important;
  }
  .__cms_flower_parent {
    overflow-x: auto !important;
    justify-content: flex-start !important;
  }
}

@media (min-width: 769px) {
  .cms-btn-shop {
    display: block;
  }

  .cms-product-width .cms-description-2 {
    display: none;
  }
}

.__cms_flower_card_color {
  width: 16px;
  height: 16px;
}

.__cms_flower_card_title {
  font-size: 19px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.__cms_flower_card_price {
  font-size: 14px;
}

@media (max-width: 768px) {
  .__cms_flower_parent {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
  }
  .__cms_flower_card_color {
    width: 12px;
    height: 12px;
  }

  .__cms_flower_card_title {
    font-size: 14px;
    margin-top: 9px;
    line-height: 1;
  }

  .__cms_flower_card_price {
    font-size: 10px;
  }
  .__cms_flower .__cms_flower_card .__cms_flower_card_content.__cms_flower_card_content_slider {
    width: 200px;
    height: 200px;
  }
  .__cms_btn_shopb {
    width: 95%;
    margin: 20px auto;
  }
  .cms-slider-btn-l {
    left: -30px;
  }
  .cms-slider-btn-r {
    right: -30px;
  }
}

.cms-content-slider {
  width: 80%;
  margin: 0 auto;
}

@media (min-width: 1023px) {
  .cms-subcontainer-1 h2 {
    font-size: 40px;
    line-height: 48px;
  }

  .cms-container-parent {
    background-position-x: 50% !important;
  }

  .cms-subcontainer-2 img {
    display: none;
  }

  .cms-subcontainer-1 .cms-description {
    display: block;
    font-size: 18px;
    line-height: 27px;
  }

  .cms-subcontainer-1 .cms-subtitle {
    font-size: 22px;
  }

  .cms-subcontainer-1 {
    padding-left: 0;
  }

  .cms-content-slider {
    width: 100%;
  }
}


/* CATEGORIES BLOCK */
.cms-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .cms-categories {
    grid-template-columns: 20% 80%;
  }
}

.cms-categories.single-column {
  grid-template-columns: 1fr;
}

.cms-categories-slider {
  display: flex;
  overflow-x: auto;
  width: 100vw;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.cms-categories-slider .cms-categories-slider-content {
  width: 110px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cms-categories-slider .cms-categories-slider-content a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cms-categories-slider::-webkit-scrollbar {
  display: none;
}

.cms-categories-slider img {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border-radius: 50%;
}

.cms-categories-slider p {
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
}

.cms-categories-slider-btn-l {
  left: 0;
}

.cms-categories-slider-btn-r {
  right: 0;
}

.cms-categories-slider-btn-l svg,
.cms-categories-slider-btn-r svg {
  width: 18px;
  height: 18px;
}

.cms-categories-slider-btn-l,
.cms-categories-slider-btn-r {
  display: none !important;
}

@media (min-width: 720px) {
  .cms-categories-slider {
    width: auto;
  }

  .cms-categories-slider-btn-l,
  .cms-categories-slider-btn-r {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .cms-categories-slider {
    /* grid-template-columns: repeat(6, 141px); */
    gap: 5px;
  }
  .cms-categories-slider .cms-categories-slider-content {
    width: 147px;
  }

  .cms-categories-slider img {
    width: 142.61px;
    height: 142.61px;
  }

  .cms-categories-slider p {
    font-size: 12.68px;
  }
}

@media (min-width: 1200px) {
  .cms-categories .cms-categories-content-1 {
    width: 236px;
  }
  .cms-categories-slider {
    width: 930px;
  }
}

.cms-text-static .text-big {
  font-size: 15px;
}

.cms-text-static .text-small {
  font-size: 11px;
}

@media (min-width: 768px) {
  .cms-text-static .text-big {
    font-size: 19px;
  }

  .cms-text-static .text-small {
    font-size: 14px;
  }
}

/* Banner Component */
.cms-banner-main {
  position: relative;
  width: 100%;
  height: 600px;
}

.cms-banner-main .cms-banner-bg {
  position: absolute;
  padding: 10px;
  width: 100%;
  background-image: url(https://sbxcloud.com/www/ibuyflowers/cms-ui/images/bg-discover-summer-2.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  align-items: center;
  background-position-x: 50%;
  background-position-y: 100%;
  justify-content: center;
  height: 100%;
}

.cms-banner-content {
  display: flex;
  min-height: 200px;
  padding: 10px;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 50%;
  transform: translate(8%, -50%);
}

.cms-banner-content-text {
  min-height: 200px;
  padding: 10px;
  width: 45%;
}

.cms-banner-first-text {
  color: rgb(224, 25, 131);
  font-weight: 500;
  padding: 10px 0 10px 4px;
}

.cms-banner-second-text {
  font-size: 46px;
  line-height: 55px;
  color: rgb(12, 51, 31);
  padding: 10px 0;
}

.cms-banner-third-text {
  font-size: 18px;
  line-height: 27px;
  color: black;
  padding: 10px 0;
}

.cms-banner-button-div {
  margin-top: 18px;
  padding: 12px 0;
}

.cms-banner-button-div a {
  background-color: rgb(224, 25, 131);
  color: white;
  padding: 12px 32px;
  border-radius: 6px;
}

.cms-banner-main-mobile {
  display: none;
}

@media (max-width: 768px) {
  .cms-banner-main {
    height: 100%;
  }

  .cms-banner-main .cms-banner-bg {
    position: relative;
    height: 300px;
  }
  .cms-banner-content {
    position: relative;
    top: auto;
    transform: translate(0, 0);
    background-color: rgba(245, 245, 245, 0.8);
    padding-top: 5px;
    width: 100%;
  }

  .cms-banner-content-text {
    width: 100%;
  }

  .cms-banner-first-text {
    font-size: 20px;
    padding-top: 5px;
  }

  .cms-banner-second-text {
    font-size: 24px;
    line-height: 28px;
  }

  .cms-banner-third-text {
    font-size: 16px;
    line-height: 24px;
  }

  .cms-banner-button-div {
    margin-top: 3px;
  }

  .cms-banner-button-div a {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .cms-banner-main .cms-banner-bg {
    height: 150px;
  }

  .cms-banner-content {
    min-height: 100px;
  }

}

/* Image Banner */
.cms-img-banner {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.cms-img-banner .cms-img-banner-1,
.cms-img-banner .cms-img-banner-2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 14px 20px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.cms-img-banner-1 .cms-img-banner-text,
.cms-img-banner-2 .cms-img-banner-text-2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.cms-img-banner-1 .cms-img-banner-text h3,
.cms-img-banner-2 .cms-img-banner-text-2 h3 {
  font-size: 13.48px;
}

.cms-img-banner-1 .cms-img-banner-text h1,
.cms-img-banner-2 .cms-img-banner-text-2 h1 {
  font-size: 24px;
  line-height: 28.8px;
  margin: 10px 0;
}

.cms-img-banner-1 .cms-img-banner-text p {
  font-size: 18px;
  display: none;
}

.cms-img-banner-1 .cms-img-banner-text div,
.cms-img-banner-2 .cms-img-banner-text-2 div {
  margin-top: 15px;
  margin-bottom: 20px;
}

.cms-img-banner-1 .cms-img-banner-text a,
.cms-img-banner-2 .cms-img-banner-text-2 a {
  border-style: solid;
  border-width: 1px;
  padding: 12px 32px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 15.6px;
}

.cms-img-banner-2 .cms-img-banner-text-2 h3 {
  font-size: 16px;
}

.cms-img-banner-2 .cms-img-banner-text-2 p {
  font-size: 18px;
}

.cms-img-banner .cms-img-banner-main {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.cms-img-banner .cms-img-banner-main-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 1190px) {
  .cms-img-banner .cms-img-banner-main {
    width: 65%;
    flex-direction: row;
  }

  .cms-img-banner .cms-img-banner-main-2 {
    width: 35%;
  }

  .cms-img-banner {
    gap: 20px;
    flex-direction: row;
  }

  .cms-img-banner .cms-img-banner-1 {
    padding: 50px 30px;
  }

  .cms-img-banner-1 .cms-img-banner-text {
    width: 50%;
  }
  .cms-img-banner-1 .cms-img-banner-text h3 {
    font-size: 16px;
    line-height: 16px;
  }
  .cms-img-banner-1 .cms-img-banner-text h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 10px 0;
  }
  .cms-img-banner-1 .cms-img-banner-text p {
    display: block;
  }
  .cms-img-banner-1 .cms-img-banner-text div {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .cms-img-banner-1 .cms-img-banner-text a,
  .cms-img-banner-2 .cms-img-banner-text-2 a {
    font-size: 14px;
  }

  .cms-img-banner-2 .cms-img-banner-text-2 {
    width: 50%;
  }
  .cms-img-banner-2 .cms-img-banner-text-2 h3 {
    font-size: 16px;
    line-height: 16px;
  }
  .cms-img-banner-2 .cms-img-banner-text-2 h1 {
    font-size: 28px;
    line-height: 33.6px;
    margin: 10px 0;
  }
  .cms-img-banner-2 .cms-img-banner-text-2 p {
    font-size: 18px;
  }
  .cms-img-banner-2 .cms-img-banner-text-2 div {
    margin: 14px 0 20px 0;
  }

  .cms-img-banner-2 .cms-img-banner-text-2 {
    width: 50%;
  }

  .cms-img-banner .cms-img-banner-2 {
    padding: 20px;
  }
}

button:disabled,
input:disabled {
  opacity: 0.5 !important;
}


.cms-navbar-default {
  min-height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  left: 0;
}

.cms-nav-open-menu {
  display: none;
}

.cms-navbar-default .nav-desktop-content {
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.cms-nav-content-mobile {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  background: white;
}


@media (max-width: 768px) {
  .cms-navbar-default .cms-nav-open-menu {
    display: block;
  }

  .cms-nav-content-mobile[aria-open="true"] {
    display: block;
  }
}

.cms-nav-content-mobile .cms-nav-close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
}


[cms-step] {
  display: none;
  width: 100%;
  /*add animation 1 times to show from left*/

}

[cms-step][aria-visible="yes"] {
  display: block;
}