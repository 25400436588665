.tabs {

    .tab-options {
      display: flex;
      gap: 20px;
      border-bottom: 1px solid #edeeee;
      padding: 0 10px;
  
      .tab {
          padding: 20px 0;
          position: relative;
          color: #c4c4c4;
          border: 0;
          background-color: transparent;
          
          &.selected {
              color: black;
              &::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 2px;
                  background-color: #1c63ec;
              }
          }
      }
    }
}