
.calendar-months {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;

  .arrow {
    background-color: #edf1fb;
    color: #1c63ec;
    padding: 8px 14px;
    border-radius: 50%;
    border: 0;
  }
}

.calendar-container {
  display: grid;
  gap: 20px;

  .day-name {
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
  }
}

.calendar-days-btn {
  border: 1px solid #d1dcea;
  background-color: #edf1fb;
  color: #1c63ec;
  font-size: 13px;
  height: 45px;
  width: 45px;
  font-weight: bold;
  border-radius: 50%;

  &-unavailable {
    background-color: #f7f7f7;
    color: #939596;
    cursor: not-allowed;
    font-size: 13px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    opacity: .4;
  }

  &.selected {
    border-color: #1c63ec;
    color: #1c63ec;
  }

  &:disabled {
    border: 2px dashed #e7ebea;
    color: #939596;
    background-color: white;
    cursor: not-allowed;
  }

  &[aria-selected="true"] {
    border-color: #1c63ec;
    color: #1c63ec;
  }

  &[aria-event="true"] {
    background-color: #1c63ec;
    border-color: #1c63ec;
    color: white;
  }

  &[aria-event-selected="true"] {
    background-color: #20c7e5;
    border-color: #20c7e5;
    color: white;
    animation: pulse 1s infinite;

    @keyframes pulse {

      0%, 100% {
        border: 2px solid #20c7e5;
      }

      50% {
        border: 1px solid #fdfdfd;

      }

    }
  }

}

// Appointments
.event-detail {
  border-right: 2px solid #f7f7f7;
  padding-right: 15px;
  display: flex;
  width: 250px;
  flex-direction: column;

  .time {
    color: #a8a8a8;
    font-weight: bold;
    display: flex;
    gap: 4px;
    align-items: center;

    p {
      margin: 0;
    }
  }

  .link {
    margin-top: 10px;

    a {
      color: #0a66b7 !important;
      text-decoration: underline !important;
    }
  }
}

.event-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  scroll-behavior: smooth;
  overflow: auto;
  padding-right: 5px;
}

.event-btn {
  display: block;
  border: 1px solid #5b92ff;
  border-radius: 2px;
  color: #1c63ec;
  padding: 10px 40px;
  text-align: center;
  width: 100%;
  background-color: white;


  &[aria-selected="true"] {
    background-color: #1c63ec;
    color: white;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  &:disabled {
    border: 2px dashed #e7ebea;
    color: black;
  }

  .warning-message {
    margin-top: 5px;
    color: red;
    text-transform: uppercase;
    font-size: 11px;
  }

  .status {
    display: inline-block;
    border: 1px solid #c9c7c9;
    color: #939596;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0 10px;
  }
}

.calendar-month-in {
  animation: calendar-month-in .5s ease-in-out;
}

.calendar-month-out {
  animation: calendar-month-out .5s ease-in-out;
}

@keyframes calendar-month-in {
  from {
    transform: translateX(-40px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes calendar-month-out {
  from {
    transform: translateX(40px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}